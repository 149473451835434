import jQuery from 'jquery/dist/jquery';
import Utils from '../../../../../utils'
import DefaultController from "../../../../defaultController";

export default class ErpStockWarehouseLocationList {
    private datatables: any = [];
    private parent: any;
    constructor(parent: any) {
        this.parent = parent
        this.loadAll();
        this.bindListeners();
    }
    loadDatatables() {
        this.datatables.forEach((table: any) => {
            table.destroy();
        })
        const elems = document.querySelectorAll(".datatable-erp-product-stock") as NodeListOf<HTMLElement>;
        elems.forEach((elem) => {
            const t = jQuery(elem).DataTable({
                processing: true,
                dom:
                    '<"row me-2 align-items-center"' +
                    '<"col-md-2"<"me-3 m-3"l>>' +
                    '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                    '>t' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info":           `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: [
                ]
            });
            // @ts-ignore
            this.datatables.push(t)
        })

    }
    async loadAll() {
        const warehouseData = await Utils.entity.getAll('erp/warehouses')
        const warehouses = warehouseData.data
        const parentHeader = document.querySelector("#erp-stock-tabs-header") as HTMLElement;
        const parentContent = document.querySelector("#erp-stock-tabs-content") as HTMLElement;

        parentHeader.innerHTML = "";
        parentContent.innerHTML = "";
        warehouses.forEach((warehouse: any, i: number) => {
            let headingHtml = `<li class="nav-item" role="presentation">
              <button type="button" class="nav-link ${i === 0 ? "active" : ""}" role="tab" data-bs-toggle="tab" data-bs-target="#erp-product-erp-stock-w${warehouse.id}" aria-controls="erp-product-erp-stock-w${warehouse.id}">
                ${warehouse.name}
              </button>
            </li>`;
            let contentHtml = `<div class="tab-pane fade ${i === 0 ? "show active" : ""}" id="erp-product-erp-stock-w${warehouse.id}" role="tabpanel">
              <table class="datatables datatable-erp-product-stock table">
                  <thead class="border-top">
                  <tr>
                    <th>${Utils.translate("erp.warehouse_location.name")}</th>
                    <th>${Utils.translate("erp.customer.name")}</th>
                    <th>${Utils.translate("erp.warehouse_location.attributes.serials")}</th>
                    <th>${Utils.translate("erp.export.attributes.stock")}</th>
                    <th>${Utils.translate("erp.export.attributes.stockReserved")}</th>
                    <th>${Utils.translate("erp.priority.name")}</th>
                  </tr>
                  </thead>
                  <tbody>`;
            contentHtml += "";

            contentHtml += ` </tbody></table></div>`;
            parentHeader.innerHTML = parentHeader.innerHTML + headingHtml;
            parentContent.innerHTML = parentContent.innerHTML + contentHtml;
        })
        this.loadDatatables();
    }

    getEntityData(elem: any) {
        return {}
    }

    bindListeners() {
    }
}